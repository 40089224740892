<template>
  <b-card title="Approving Seller Acoounts">
    <b-modal
          id="modal-1"
          title="Confirmation"
          ok-only
          ok-title="Yes"
          @ok="activateSeller"
          :hide-footer="alertMessage == 1"
      >
          <b-card-text>
              <span v-if="alertMessage == 0">
                
                  Are you sure you want to process further?
              </span>
              
              <span v-else-if="alertMessage == 1">
                  <span v-if="returnMessageStatus == 0">
                      <b-alert
                      variant="success"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
                  <span v-else-if="returnMessageStatus == 1">
                      <b-alert
                      variant="danger"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
              </span>
          </b-card-text>
      </b-modal>

      <b-modal
          id="modal-2"
          title="CNIC Front"
          ok-only
          ok-title="Close"
      >
          <b-card-text>
              
              <b-img :src="this.cnic_front_image" fluid alt="Fluid image"></b-img>
             
          </b-card-text>
      </b-modal>

      <b-modal
          id="modal-3"
          title="CNIC Back"
          ok-only
          ok-title="Close"
      >
          <b-card-text>
              
              <b-img :src="this.cnic_back_image" fluid alt="Fluid image"></b-img>
             
          </b-card-text>
      </b-modal>
     
      <!-- table -->
        <vue-good-table
          ref="unverified-sellers"
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :isLoading="isLoadingProp" 
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :totalRows="totalRecords"
          @on-column-filter="onColumnFilter"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
        <div slot="loadingContent">
          <b-spinner
            ref="spinner_one"
            variant="primary"
            class="mr-1"
            type="grow"
            style="width: 4rem; height: 4rem;"
          />
        </div>
        <div slot="table-actions">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-b-modal.modal-1
            v-on:click="activeBtn"
            >
            Mark Active
        </b-button>
        <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="reconciled"
            >
            Mark as Reconciled
        </b-button> -->
      </div>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <!-- <span
              v-if="props.column.field === 'fullNamae'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span> -->

          
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
                <span v-if="props.row.cnic_front != null">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        v-b-modal.modal-2
                        v-on:click="adjustBtn(props.row.cnic_front,1)"
                        >
                        CNIC Front
                    </b-button>
                </span>
                
                <p> </p>
                <span v-if="props.row.cnic_back != null">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        v-b-modal.modal-3
                        v-on:click="adjustBtn(props.row.cnic_back,2)"
                        >
                        CNIC Back
                    </b-button>
                </span>

                <p> </p>
                <span v-if="props.row.certificate != null">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        v-on:click="adjustBtn(props.row.certificate,3)"
                        >
                        Certificate
                    </b-button>
                </span>
            </span>

           
            
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
   

    

    

    
    
  </b-card>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown,BCard,BButton,BTabs,BTab,BSpinner,BCardText,BAlert,BImg,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import pages from '@/router/routes/pages'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueGoodTable,
    BImg,
    BCard,
    BAlert,
    BCardText,
    BSpinner,
    BTab,
    BTabs,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      allData:null,
      log: [],
      pageLength: 10,
      totalRecords:0,
      cnic_front_image:null,
      cnic_back_image:null,
      certificate:null,
      dir: false,
      focusedCnCount:0,
      isLoadingProp: true,
      alertMessage:0,
      msgBoxMessage:'',
      pages: ['10','20','50','100'],
      columns: [
        {
          label: 'Seller ID',
          field: 'id',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Seller Name',
          field: 'full_name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Company Name',
          field: 'company_name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Entity Type',
          field: 'entity_type',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Bill To',
          field: 'bill_to',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Secp',
          field: 'secp',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Billing Address',
          field: 'billing_address',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Contact Person',
          field: 'contact_person',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Billing Email',
          field: 'billing_email',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'IBAN',
          field: 'iban',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Bank',
          field: 'bank',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Attachments',
          field: 'action',
          sortable: false,
        },
       
      ],
      rows: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '', // 'asc' or 'desc'
        },
        page: 1, // what page I want to show
        perPage: 10, // how many items I'm showing per page
      },
      searchTerm: '',
      
    }
  },
  computed: {
    
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
      this.$http.post('get-unverified-sellers-all',{
        data:this.serverParams,
        })
        .then(res => { this.rows = res.data.data;
          this.totalRecords = res.data.totalRecords;
          this.updateAllData(res.data.data);
          this.isLoadingProp = false;
        })
   

  
    
  },
  
  methods: {
    
    updateAllData(data){
      this.allData = null;
      this.allData = data;
    },
    updateParams(newProps) {
        
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    // handleSearch(searching) {
    //   this.log.push(`The user searched for: ${searching}`)
    // },
    handleChangePage(page) {
      this.updateParams({page: page});
      this.log.push(`The user changed the page to: ${page}`)
      this.loadItems();
    },
    handlePageChange(active) {
      this.updateParams({perPage: active});
      this.log.push(`the user change page:  ${active}`)
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
      this.log.push(`the user ordered:  ${params[0].type}`)
    },
    onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
    },

    afterSubmit(message,error){
        this.alertMessage = 1;
        this.returnMessageStatus = error;
        this.returnMessage = message;
        this.loadItems();
    },
    
    loadItems() {
      this.isLoadingProp = true;
        this.$http.post('get-unverified-sellers-all',{
            data:this.serverParams,
        }).then(response => {
            //  this.totalRecords = response.totalRecords;
            this.totalRecords = response.data.totalRecords;
            this.rows = response.data.data;
            this.updateAllData(response.data.data);
            this.isLoadingProp = false;
        });
      
      
    },

    downloadItem ( url ) {
        window.open(url);
    },

    adjustBtn(data,type){
        if(type == 1){
            this.cnic_front_image = data;
        } else if(type == 2){
            this.cnic_back_image = data;
        } else if(type == 3){
            // this.certificate = data;
            this.downloadItem(data)
        }
    },
   
    activeBtn(){
      
        this.alertMessage = 0;
        this.returnMessageStatus = 0;
        this.returnMessage = "";
    },
    activateSeller: function (event) {
        this.alertMessage = 0;
        this.returnMessageStatus = 0;
        this.returnMessage = "";
      event.preventDefault();
      if((this.$refs['unverified-sellers'].selectedRows).length > 0){
        this.isLoadingProp = true;
        this.$http.post('verify-seller-billing-profile',this.$refs['unverified-sellers'].selectedRows).then(response => {
        this.afterSubmit(response.data.message,response.data.error);
        });
      } else{
        this.alertMessage = 1;
        this.returnMessageStatus = 1;
        this.returnMessage = "Please select a row";
      }
      
    },
 
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>